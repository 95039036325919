<template>
  <div class="payment-form">
    <component
      :is="components[paymentMethod]"
      :paymentDetails="paymentDetails"
      @paid="$emit('paid')"
    />
  </div>
</template>

<script>
import Iframe from '@/components/PaymentForms/Iframe'
import YooKassa from '@/components/PaymentForms/YooKassa'

export default {
  props: ['paymentMethod', 'paymentDetails'],
  components: {
    Iframe,
    YooKassa,
  },
  created() {
    if (!Object.keys(this.components).includes(this.paymentMethod))
      this.$emit('paid')
  },
  data() {
    return {
      components: {
        IFRAME: Iframe,
        CREDIT_CARD: YooKassa,
      },
    }
  },
}
</script>
