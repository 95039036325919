<template>
  <div class="iframe-payment-form">
    <p>{{ $t('payment.iframe.title') }}</p>
  </div>
</template>

<script>
export default {
  props: ['paymentDetails'],
  created() {
    window.open(this.paymentDetails.formUrl, '_self')
  },
}
</script>

<style lang="scss">
.iframe-payment-form {
  text-align: center;
  font-size: 18px;
}
</style>
