<template>
  <div class="next-button-wrapper" :class="{ withoutMarginRight }">
    <div
      class="next-button"
      v-if="!onlyFloating"
      ref="btn"
      @click="handleClick"
      :class="{ disabled }"
    >
      <div class="label">{{ label ? label : $t('settings.next') }}</div>
    </div>

    <div
      class="floating"
      @click="handleClick"
      :class="{
        show: floating && (onlyFloating || showFloatBtn),
        hasLabel: floatingLabel,
        disabled,
        floatingWithoutFixed,
      }"
    >
      <md-icon v-if="floatingIcon">{{ floatingIcon }}</md-icon>
      <span class="label">{{ floatingLabel }}</span>

      <div class="counter" v-if="floatingCounter && floatingCounter > 0">
        <span>{{ floatingCounter }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'label',
    'click',
    'floating',
    'onlyFloating',
    'floatingIcon',
    'floatingCounter',
    'floatingLabel',
    'floatingWithoutFixed',
    'disabled',
    'withoutMarginRight',
  ],
  data() {
    return {
      showFloatBtn: true,
    }
  },
  mounted() {
    let updateFloatBtnInt = setInterval(this.updateFloatBtn, 100)
    setTimeout(() => {
      clearInterval(updateFloatBtnInt)
    }, 2500)
  },
  methods: {
    updateFloatBtn() {
      let btn = this.$refs.btn
      if (!btn) return
      this.showFloatBtn = btn.getBoundingClientRect().top > window.innerHeight
    },
    handleClick() {
      if (this.disabled) return
      if (typeof this.click == 'function') this.click()
    },
    _resize() {
      this.updateFloatBtn()
    },
    _scroll() {
      this.updateFloatBtn()
    },
  },
}
</script>

<style lang="scss">
.next-button-wrapper {
  text-align: right;
  margin-right: 15px;

  &.withoutMarginRight {
    margin-right: 0;
  }

  > .floating {
    position: fixed;
    right: 20px;
    bottom: 25px;
    width: 48px;
    height: 48px;
    border-radius: 100px;
    background-color: #60a844;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.3);
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
    transition: 0.2s all;
    z-index: 10;
    color: white;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: brightness(1);

    &.floatingWithoutFixed {
      position: initial;
      right: initial;
      bottom: initial;
    }

    &.hasLabel {
      width: initial;
      height: initial;
    }

    &.show {
      opacity: 1;
      pointer-events: initial;
    }

    &:hover {
      filter: brightness(1.1);
    }

    > .md-icon {
      color: white;
    }

    > .counter {
      position: absolute;
      top: 0;
      right: 0;
      background-color: red;
      color: white;
      padding: 10px;
      font-size: 12px;
      border-radius: 100%;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  > *.disabled {
    pointer-events: none;
    filter: opacity(0.5) !important;
    cursor: initial;
  }
}

.next-button {
  position: relative;
  display: inline-block;
  background-color: #60a844;
  cursor: pointer;
  margin-bottom: 15px;
  margin-top: 10px;
  transition: 0.2s all;
  box-shadow: 0px 2px 4px 0px rgba(50, 50, 50, 0.4);

  &:hover {
    filter: brightness(1.1);
  }

  > *,
  > a {
    padding: 20px 15px;
    display: block;
    color: white !important;
    -webkit-text-fill-color: white !important;
    text-transform: uppercase;
    font-size: 14px;
    font-family: Oswald;

    &:hover {
      text-decoration: none !important;
    }
  }
}
</style>
