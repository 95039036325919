import { structuredClone } from 'core-js'

export default function getServerAttrValue(attr) {
  let value = structuredClone(attr.value)

  if (attr['@type'] == 'slots') {
    value.startTime = transformDateFromServerToClient(value.startTime)
    value.endTime = transformDateFromServerToClient(value.endTime)
  }

  if (['date_time', 'date'].includes(attr['@type'])) {
    value = transformDateFromServerToClient(value)
  }

  return value
}

function transformDateFromServerToClient(date) {
  if (typeof date == 'string' && String(Number(date)) == date)
    date = Number(date)

  return new Date(new Date(date).normalize(true))
}
