<template>
  <div id="yookassa-payment-form"></div>
</template>

<script>
export default {
  props: ['paymentDetails'],
  data() {
    return {
      checkout: null,
    }
  },
  mounted() {
    this.checkout = new window.YooMoneyCheckoutWidget({
      confirmation_token: this.paymentDetails.accessToken,
      error_callback: error => {
        this.$events.emit('show-alert', this.$t('errors.error'), error)
        throw error
      },
    })

    this.checkout.on('success', () => {
      this.$emit('paid')
      this.checkout.destroy()
    })

    this.checkout.on('fail', () => {
      this.$events.emit(
        'show-alert',
        this.$t('errors.error'),
        this.$t('errors.payment')
      )
      this.$emit('paid')
      this.checkout.destroy()
    })

    this.checkout.render('yookassa-payment-form')
  },
  beforeDestroy() {
    this.checkout?.destroy()
  },
}
</script>
