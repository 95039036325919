<template>
  <div id="order" v-if="order" :class="{ loading }">
    <TopMenu
      :showLangSelector="true"
      :title="$t('order.result')"
      :customBack="
        order ? () => $router.push('/hotel/' + order.propertyId) : undefined
      "
      :hideBack="finished"
    />

    <Loading v-if="loading" />

    <div class="finished" v-if="finished">
      <md-icon class="md-size-3x" :class="{ fail }">{{
        fail ? 'error_outline' : 'check_circle_outline'
      }}</md-icon>
      <div class="text">
        {{ fail ? $t('order.fail') : $t('order.finished') }}
      </div>
    </div>

    <div
      class="completed-description"
      v-if="finished && offer"
      v-html="offer.orderCompletedDescription"
      v-html-reactive-links
    />
    <OrderCard
      :srcOrder="order"
      :ribbon="true"
      v-if="!isProcessingPayment"
      :loading="needUpdate || isUpdating"
      :disableClearBtn="finished"
      @orderUpdated="fetchOrder"
      ref="orderCard"
      :showCalendarEvent="finished"
    />
    <p
      class="payment-method"
      v-if="onlyOnePayMethod && hasPrice && !isProcessingPayment"
    >
      <b>{{ $t('order.paymentMethod') }}</b
      >: {{ paymentMethodDetails ? paymentMethodDetails.title : '' }}
    </p>

    <div
      v-if="!onlyOnePayMethod && hasPrice && !isProcessingPayment"
      class="payment-method"
    >
      <b-form-group v-if="!finished && !isProcessingPayment">
        <b-form-radio
          v-model="paymentMethodDetails"
          :value="method"
          v-for="method in order.availablePaymentMethods.filter(
            m => !m.disabled,
          )"
          :key="method.id"
          >{{ method.title }}</b-form-radio
        >
      </b-form-group>
    </div>

    <div
      class="attributes"
      v-if="showRootAttributes && !finished && !isProcessingPayment"
    >
      <AttributeWrapper
        v-for="attribute in offer.attributes"
        :key="attribute.id"
        :attribute="attribute"
        :offer="offer ? offer : null"
      />
    </div>

    <PaymentForm
      :paymentMethod="paymentMethod"
      :paymentDetails="order.paymentDetails"
      v-if="isProcessingPayment"
      @paid="onPaid"
    />

    <div
      class="min-price"
      v-if="
        typeof order.minPrice == 'number' && !finished && !isProcessingPayment
      "
    >
      {{ $t('order.minPrice') }}: {{ order.minPrice }} {{ order.priceCurrency }}
    </div>

    <NextButton
      :click="makeOrder"
      v-if="!finished && !isProcessingPayment && offer && offer.serviceId"
      :disabled="needUpdate || isUpdating"
      :label="
        [
          '52d0b758-e281-4b5b-89cc-0c10d332ccb6',
          '84580008-f713-4bf3-bb93-5b00213e459c',
        ].includes(offer.serviceId)
          ? 'Голосовать'
          : $t('order.complete')
      "
    />

    <NextButton
      :click="back"
      v-if="finished && !offer.orderCompletedDescription"
      :label="$t('settings.done')"
      style="text-align:center"
    />

    <LegalInfo :text="order.legalInfo" />
  </div>

  <div v-else-if="finished" id="order">
    <TopMenu
      :title="$t('order.payment')"
      :customBack="
        order ? () => $router.push('/hotel/' + order.propertyId) : undefined
      "
    />

    <div class="finished">
      <md-icon class="md-size-3x" :class="{ fail }">{{
        fail ? 'error_outline' : 'check_circle_outline'
      }}</md-icon>
      <div class="text">
        {{ fail ? $t('order.fail') : $t('order.finished') }}
      </div>
    </div>

    <NextButton
      :click="back"
      :label="$t('settings.done')"
      style="text-align:center"
    />
  </div>
</template>

<script>
import Vue from 'vue'

import AttributeWrapper from '@/components/Attributes/AttributeWrapper'
import TopMenu from '@/components/TopMenu'
import OrderCard from '@/components/OrderCard'
import NextButton from '@/components/NextButton'
import LegalInfo from '@/components/LegalInfo'
import PaymentForm from '@/components/PaymentForm'
import getServerAttrValue from '../lib/attributes/getServerAttrValue'

export default {
  components: {
    AttributeWrapper,
    TopMenu,
    OrderCard,
    NextButton,
    LegalInfo,
    PaymentForm,
  },
  data() {
    return {
      attributesToFill: {},
      paymentMethodDetails: null,
      serviceName: '',
      error: '',
      toUpdate: [],
      updateInterval: null,
      fail: false,
      finished: false,
      isProcessingPayment: false,
      submitOrder: {},
      cardHolder: '',
      offer: {},
      order: null,
      window,
      loading: false,
      isUpdating: false,
      activeOrdersTypes: ['pending', 'in_progress'],
    }
  },
  mounted() {
    if (this.$route.fullPath.startsWith('/payment')) {
      this.fail = this.$route.fullPath.startsWith('/payment/fail')
      this.finished = true
    }

    if (this.$route.query.orderId) this.fetchOrder(this.init)
  },
  beforeDestroy() {
    if (this.updateInterval) clearInterval(this.updateInterval)
  },
  events: {
    'attr-value-changed'(attrId) {
      const saves = this.$store.attributesSaves
      if (saves[attrId])
        Vue.set(this.attributesToFill, attrId, saves[attrId].getValue())
      if (!this.toUpdate.includes(attrId)) this.toUpdate.push(attrId)
    },
    authorized() {
      this.fetchOrder()
    },
  },
  methods: {
    fetchOrder(cb) {
      let query = this.$route.query
      if (query.orderId)
        this.$api
          .get(`user/orders/${query.orderId}?availablePaymentMethods=true`)
          .then(res => {
            this.order = res.data.data
            this.$forceUpdate()

            try {
              if (cb) cb()
              this.syncAttributesWithServer()

              if (!this.$route.fullPath.startsWith('/payment')) {
                this.isProcessingPayment =
                  this.order.paymentStatus == 'PAYMENT_IN_PROGRESS'

                this.finished = this.activeOrdersTypes.includes(
                  this.order.status.toLowerCase(),
                )

                // TODO
                // if (
                //   !this.finished &&
                //   !this.isProcessingPayment &&
                //   this.order.status != 'DRAFT'
                // ) {
                //   this.$router.replace('/orderdetails/' + query.orderId)
                //   this.$store.routerHistory.pop()
                // }
              }
            } catch (err) {
              console.error(err)
            }
          })
          .catch(() => {
            this.$store.clearNavigationBlockers()
            this.$router.push('/').then(() => window.location.reload())
            throw Error(`Can't get order [${query.orderId}]`)
          })
      else {
        this.$store.clearNavigationBlockers()
        this.$router.push('/').then(() => window.location.reload())
        throw Error(`Tried to fetch order without orderId`)
      }
    },
    watchAttributes() {
      const saves = this.$store.attributesSaves
      for (const attrId of Object.keys(saves)) {
        Vue.set(this.attributesToFill, attrId, saves[attrId].getValue())
        if (!this.toUpdate.includes(attrId)) this.toUpdate.push(attrId)
      }
    },
    init() {
      if (this.order.availablePaymentMethods)
        this.order.availablePaymentMethods.forEach(paymentMethod => {
          if (!this.paymentMethodDetails && !paymentMethod.disabled)
            this.paymentMethodDetails = paymentMethod
        })

      if (
        this.order.serviceType == 'BOOKING' &&
        Array.isArray(this.$store.selectedGuests)
      ) {
        this.$api.post(`user/orders`, {
          action: 'UPDATE_STAY',
          orderId: this.order.orderId,
          stayRequest: {
            guests: this.$store.selectedGuests,
          },
        })
      }

      this.$api.get(`offers/${this.order.orderItem.offerId}`).then(res => {
        this.offer = res.data.data
        this.watchAttributes()
      })

      document.getElementsByTagName('html')[0].scrollTop = 0
      this.$events.emit('log-event', 'add_to_cart', {
        offer: this.order.orderItem.offerId,
        title: this.order.orderItem.offerTitle,
      })

      if (this.updateInterval) clearInterval(this.updateInterval)
      this.updateInterval = setInterval(() => {
        if (this.needUpdate) {
          this.update()
        }
      }, 1000)
    },
    getPostValues() {
      let attributeValues = []
      for (let id of Object.keys(this.attributesToFill)) {
        if (!this.toUpdate.includes(id)) continue

        let value = this.attributesToFill[id]
        attributeValues.push({ id, value })

        if (this.toUpdate.includes(id))
          this.toUpdate = this.toUpdate.filter(item => item != id)
      }
      return attributeValues
    },
    syncAttributesWithServer() {
      if (!this.order.orderItem?.attributeValues) return
      for (const attr of this.order.orderItem.attributeValues) {
        if (attr.value) {
          const int = setInterval(() => {
            if (this.$store.attributesSaves[attr.id]) {
              this.$store.attributesSaves[attr.id].setValue(
                getServerAttrValue(attr),
              )
              clearInterval(int)
            }
          }, 200)
        }
      }
    },
    update() {
      if (this.isUpdating) {
        let interval = setInterval(() => {
          if (!this.isUpdating) {
            this.update()
            clearInterval(interval)
          }
        }, 100)
        return
      }
      if (this.finished || this.isProcessingPayment) return

      const attributeValues = this.getPostValues()
      this.isUpdating = true
      this.$api
        .post(`user/orders`, {
          action: 'UPDATE',
          orderId: this.order.orderId,
          availablePaymentMethods: true,
          orderItemRequests: [
            {
              action: 'UPDATE',
              orderItemId: this.order.orderItem.orderItemId,
              attributeValues,
            },
          ],
        })
        .then(res => {
          this.error = ''

          if (typeof res.data.data?.priceTotal == 'number') {
            this.order.priceTotal = res.data.data.priceTotal
          }
          if (res.data.data?.orderItem?.attributeValues) {
            this.order.orderItem.attributeValues =
              res.data.data.orderItem.attributeValues
          }
          this.updateOrder(res.data.data)
        })
        .catch(err => {
          if (err.response.data.message) {
            this.error = err.response.data.message
          }

          if (err.response.data.data?.priceTotal) {
            this.order.priceTotal = err.response.data.data.priceTotal
          }
          if (err.response.data.data?.orderItem?.attributeValues) {
            this.order.orderItem.attributeValues =
              err.response.data.data.orderItem.attributeValues
          }
          this.updateOrder(err.response.data.data)
        })
        .finally(() => {
          this.isUpdating = false
        })
    },
    updateOrder(...args) {
      if (!this.$refs.orderCard) return
      this.$refs.orderCard.updateOrder(...args)
    },
    makeOrder() {
      if (
        this.order.orderItem.orderItems.length == 0 &&
        ['FOOD', 'FOOD2', 'COMMON_CART'].includes(this.order.serviceType)
      ) {
        return this.$events.emit(
          'show-alert',
          this.$t('errors.error'),
          this.$t('errors.atLeastOneItemInCart'),
        )
      }

      if (this.error != '') {
        this.$events.emit('show-alert', this.$t('errors.error'), this.error)
        this.update()
        return
      }
      if (this.needUpdate || this.isUpdating) return

      let request = {
        action: 'SUBMIT',
        orderId: this.order.orderId,
        paymentMethodId: this.paymentMethodDetails?.id,
      }

      this.loading = true
      this.$api
        .post(`user/orders`, request)
        .then(() => {
          this.$store.attributesSaves = {}
          this.$sessionStorage.breakfastOrderIdSaves = {}
          this.$sessionStorage.commonCartOrderIdSaves = {}

          this.$events.emit('log-event', 'purchase', {
            offer: this.order.orderItem.offerId,
            title: this.order.orderItem.offerTitle,
          })

          this.fetchOrder(() => {
            this.loading = false
          })
        })
        .catch(err => {
          this.loading = false
          this.$events.emit(
            'show-alert',
            this.$t('errors.error'),
            err.response.data.message,
          )
        })
    },
    onPaid() {
      this.isProcessingPayment = false
      this.finished = true
    },
    back() {
      if (this.order?.propertyId) {
        return this.$router.replace('/hotel/' + this.order.propertyId)
      }

      if (this.$store.isAuthed) this.$router.replace('/user/orders')
      else
        this.$events.emit('do-login-popup', {
          callback: () => this.$router.replace('/user/orders'),
        })
    },
    _resize() {
      if (typeof this.$refs.footer === 'undefined') return
      this.updateFixedFooter()
    },
  },
  computed: {
    showRootAttributes() {
      if (!this.order) return false
      return ['FOOD', 'FOOD2', 'ROOM_SERVICE', 'COMMON_CART'].includes(
        this.order.serviceType,
      )
    },
    paymentMethod() {
      if (this.order.paymentStatus == 'PAYMENT_IN_PROGRESS')
        return this.order.paymentMethod
      return this.paymentMethodDetails['@type']
    },
    onlyOnePayMethod() {
      if (!this.order?.availablePaymentMethods) return false
      return (
        this.order.availablePaymentMethods.filter(m => !m.disabled).length <= 1
      )
    },
    hasPrice() {
      return this.order.priceTotal > 0
    },
    needUpdate() {
      return this.toUpdate.length > 0
    },
  },
  watch: {
    finished() {
      this.$nextTick(() => window.smoothScroll({ yPos: 0 }))
    },
    paymentMethodDetails: {
      deep: true,
      handler() {
        this.update()
      },
    },
  },
}
</script>

<style lang="scss">
#order {
  &.loading {
    max-height: 100vh;
    overflow: hidden;
  }

  > .attributes {
    margin-left: 15px;
    margin-right: 15px;

    .privacy-policy-label {
      width: 100%;
    }
  }

  > .payment-method {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 10px;

    > .form-group {
      margin-bottom: 0;
    }
  }

  > .error {
    color: red;
    margin-left: 15px;
    margin-right: 15px;
  }

  > .finished {
    position: relative;
    height: 90px;

    .md-icon {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      color: rgb(124, 192, 87);

      &.fail {
        color: rgb(192, 90, 87);
      }
    }
    .text {
      position: absolute;
      left: 90px;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      font-size: 17px;
    }
  }

  > .completed-description {
    padding: 0 10px;
    padding-bottom: 15px;
  }

  > .min-price {
    text-align: right;
    font-weight: bold;
    margin: 0 15px;
    margin-top: 30px;
  }
}
</style>
